'use strict';

customTranslationStrategy.$inject = ['$translate', '$translateSanitization', '$sanitize'];

function customTranslationStrategy($translate, $translateSanitization, $sanitize) {
    //function to handle sanitization of translation text
    var translationFunction = function (value, mode) {
        if (mode === 'text') {
            var result = '';
            var element = angular.element('<div></div>');
            //sanitize value and set as encoded HTML
            element.html($sanitize(value));
            //return text version of the HTML, should decode HTML chars
            return element.text();
        }
        return value;
    };

    //set this as the translation strategy in the app
    $translateSanitization.useStrategy(translationFunction);
    $translate.refresh(); // fix bug with loading fall-back when preferred doesn't exist
}
